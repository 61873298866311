/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import {
  HeadingLayout,
  Hero3,
  NameStands,
  Teammate,
  Working,
} from 'components/elements'
import Layout from 'components/shared/Layout'
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Elements
import styled from 'styled-components'
import TeamMember from '../components/elements/TeamMember'

const HeroImage = styled.div`
  position: relative;
  width: 900px;
  
  @media (min-width: 992px) {
    margin: -200px auto 0 auto;
  }

  @media screen and (max-width: 991px) {
    margin: -250px auto 0 auto;
    width: 100%;
  }

  @media screen and (max-width: 565px) {
    margin: -300px auto 0 auto;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
    team,
  },
}) => {
  return (
    <Layout activeHeader="Over ons">
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero3
        fields={acf.banner}
        bigBottom
      />

      <HeroImage>
        <Image
          src={acf.banner.image}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      </HeroImage>

      <div className="mt-5 mt-sm-0 pt-5 pt-sm-0">
        <NameStands
          fields={acf.blok_1}
        />
      </div>

      <div data-component="about-us" className="container py-lg-5 mt-5">
        <div className="pt-5">
          <ParseContent content={acf.team.description} />
          <HeadingLayout className="mt-n5" heading={acf.team.background_title} direction="right-right">
            <div className="row">
              {team.edges.map((member, index) => {
                console.log(member);
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="col-sm-6 col-lg-4 mb-5 d-flex align-content-stretch">
                    <TeamMember fields={member} />
                  </div>
                )
              })}
              <div className="col-sm-6 col-lg-8 mb-5 d-flex align-content-stretch">
                <Working
                  fields={acf.team.work_at}
                />
              </div>
            </div>
          </HeadingLayout>
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1000, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        blok_1 {
          description
          points {
            title
            description
          }
        }

        team {
          description
          background_title
          work_at {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 750) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            description
            link {
              url
              title
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }

    team: allWordpressWpTeamleden(sort: {fields: date, order: ASC}) {
      edges {
        node {
          acf {
            team {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              description
              e_mailadres
              linkedin_url
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
